import React from "react";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import { Link } from "gatsby";
import { CgbImageLinkBlock } from "../../blocks/CgbImageLinkBlock";
import linkCart from "../../../images/link-cart.png";

import styles from "./styles";

import {
  addToWishlist,
  removeFromWishlist,
  closeOverlay,
} from "../../../app/actions";

const mapStateToProps = (state) => ({
  wishlist: state.WishlistSettings,
  overlay: state.OverlaySettings,
});

const mapDispatchToProps = (dispatch) => ({
  addToWishlist: (item) => dispatch(addToWishlist(item)),
  removeFromWishlist: (item) => dispatch(removeFromWishlist(item)),
  closeOverlay: () => dispatch(closeOverlay()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class ProductOverlay extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.wishlist !== this.props.wishlist) {
      Cookies.set("wishlist", this.props.wishlist);
    }
  }

  wishlistHandler() {
    const { wishlist, addToWishlist, removeFromWishlist, code } = this.props;

    if (wishlist.includes(String(code))) {
      removeFromWishlist(String(code));
    } else {
      addToWishlist(String(code));
    }
  }

  render() {
    const { classes, wishlist, overlay, closeOverlay, code, uri } = this.props;

    return (
      <>
        <div className={`${classes.overlay} ${overlay.opened ? "opened" : ""}`}>
          <section>
            <button onClick={() => closeOverlay()} className="close">
              x
            </button>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="center"
              className={`image-link-row ${classes.imageLinkRow} ${classes.imageLinkRowTheme}`}
            >
              <Grid item xs={12} onClick={() => closeOverlay()}>
                <div className="links">
                  <button
                    className={
                      wishlist.includes(String(code))
                        ? "wishlist added"
                        : "wishlist"
                    }
                    onClick={() => this.wishlistHandler()}
                  >
                    Add to Quote Builder
                  </button>
                  <Link
                    className="contact"
                    to={"/about-us/contact-us"}
                    onClick={() => closeOverlay()}
                  >
                    Contact Us
                  </Link>
                </div>
              </Grid>
            </Grid>
          </section>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(connector(ProductOverlay));
