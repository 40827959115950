import React from "react";
import { Link } from "gatsby";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import ReactHtmlParser from "react-html-parser";
import styles from "./styles";
import Img from "gatsby-image";
import { transformLink } from "../../../helpers/";
import SocialShare from "../../SocialShare";

import ProductOverviewIcons from "../ProductOverviewIcons";
import { CgbAccordionBlock } from "../../blocks/CgbAccordionBlock";
import { openOverlay } from "../../../app/actions";

const mapDispatchToProps = (dispatch) => ({
  openOverlay: () => dispatch(openOverlay()), //eslint-disable-line
});

const connector = connect(null, mapDispatchToProps);

const useStyles = makeStyles((theme) => styles(theme));

function convertYoutubeLinkToEmbed(link) {
  if (link === null) {
    return null;
  }

  const youtubeLink = link.split("watch?v=");
  return `${youtubeLink[0]}embed/${youtubeLink[1]}`.replace("&t=", "?start=");
}

function ProductOverview(props) {
  const classes = useStyles();

  let overviewSplit = ["", ""];

  if (props.overview !== null) {
    overviewSplit = props.overview.split("<p>{icons}</p>");
  }

  const faqs = [];

  if (props.faqs !== null) {
    props.faqs.forEach((faq) => {
      faqs.push({
        attributes: {
          className: "",
          open: false,
        },
        innerBlocks: [
          {
            attributes: {
              align: "",
              anchor: "",
              backgroundColor: "",
              className: "",
              content: faq.question,
              level: 3,
              textColor: "",
              __typename: "WpCoreHeadingBlockAttributes",
            },
            innerBlocks: [],
            name: "core/heading",
            __typename: "WpCoreHeadingBlock",
          },
          {
            attributes: {
              align: "",
              backgroundColor: "",
              className: "",
              content: faq.answer,
              direction: "",
              textColor: "",
              __typename: "WpCoreParagraphBlockAttributes",
            },
            innerBlocks: [],
            name: "core/paragraph",
            __typename: "WpCoreParagraphBlock",
          },
        ],
        name: "cgb/accordion-inner",
        __typename: "WpCgbAccordionInnerBlock",
      });
    });
  }

  const [activeTab, setActiveTab] = React.useState("overview");

  return (
    <div
      className={`product-overview  ${classes.productOverview}  ${classes.productOverviewTheme}`}
    >
      <div className="overview-nav">
        <div className="wrapper">
          <button
            onClick={() => setActiveTab("overview")}
            onKeyDown={() => setActiveTab("overview")}
            data-active={"overview" === activeTab ? true : false}
          >
            Overview
          </button>
          {props.faqs !== null && (
            <button
              onClick={() => setActiveTab("faq")}
              onKeyDown={() => setActiveTab("faq")}
              data-active={"faq" === activeTab ? true : false}
            >
              FAQs
            </button>
          )}
          {props.specBlurb !== "" && (
            <button
              onClick={() => setActiveTab("specifiers")}
              onKeyDown={() => setActiveTab("specifiers")}
              data-active={"specifiers" === activeTab ? true : false}
            >
              Specifiers
            </button>
          )}
        </div>
      </div>
      {activeTab === "overview" && (
        <div className="overview">
          <h2>Overview</h2>
          <div className="overview-columns">
            <div className="content">
              {props.overview !== null &&
                ReactHtmlParser(overviewSplit[0], { transform: transformLink })}
              {props.icons !== null && (
                <ProductOverviewIcons icons={props.icons} />
              )}
              {props.overview !== null &&
                ReactHtmlParser(overviewSplit[1], { transform: transformLink })}
              <div className="actions">
                {props.brochure !== null && (
                  <a
                    href={props.brochure.localFile.publicURL}
                    target="_blank"
                    className="brochure"
                    rel="noreferrer"
                  >
                    Brochure
                  </a>
                )}
                <SocialShare
                  path={props.pageContext.pagePath}
                  title={props.title}
                />
              </div>
              {props.datasheet !== null || props.instruction !== null ? (
                <div className="usefull-links">
                  <h4>Helpful files</h4>
                  <div className="links">
                    {props.datasheet !== null && (
                      <a
                        href={props.datasheet.localFile.publicURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Datasheet
                      </a>
                    )}
                    {props.instruction !== null && (
                      <a
                        href={props.instruction.localFile.publicURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Instructions
                      </a>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="features">
              {props.gallery && (
                <div className="gallery">
                  <Img fluid={props.gallery.localFile.childImageSharp.fluid} />
                </div>
              )}

              {props.features !== null && (
                <div className="features-list">
                  <h3>Features</h3>
                  <ul>
                    {props.features.map((feature, index) => {
                      return (
                        <li key={`ulli-${index}`}>
                          {ReactHtmlParser(feature.feature)}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              <div
                style={{
                  marginBlockStart: "1em",
                }}
              >
                <h3>Accreditations</h3>
                <div className="product-accreditations">
                  {props.accreditations.nodes.map((image) => {
                    if (image.AccreditationLogo.logo !== null) {
                      return (
                        <div
                          className="accred-logo"
                          key={image.AccreditationLogo.logo.id}
                        >
                          <Img
                            fluid={
                              image.AccreditationLogo.logo.localFile
                                .childImageSharp.fluid
                            }
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.faqs !== null && activeTab === "faq" && (
        <div className="product-faqs">
          <h2>Frequently asked questions</h2>
          <CgbAccordionBlock
            attributes={{
              className: "",
            }}
            innerBlocks={faqs}
          />
        </div>
      )}
      {props.specBlurb !== "" && activeTab === "specifiers" && (
        <div className="specifiers">
          <div className="content-col">
            <h2>For Specifiers</h2>
            {ReactHtmlParser(props.specBlurb)}

            <div className="actions">
              <Link to="/about-us/contact-us/">Talk to our team</Link>
            </div>
            {props.datasheet !== null ||
            props.instruction !== null ||
            props.bmi !== null ||
            props.specificationBrochure !== null ? (
              <div className="usefull-links">
                <h4>Helpful files</h4>
                <div className="links">
                  {props.datasheet !== null && (
                      <a
                        href={props.datasheet.localFile.publicURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Datasheet
                      </a>
                  )}
                  {props.instruction !== null && (
                      <a
                        href={props.instruction.localFile.publicURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Instructions
                      </a>
                  )}
                  {props.bmi !== null && (
                      <a
                        href={props.bmi.localFile.publicURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        BMI file
                      </a>
                  )}
                  {props.specificationBrochure?.localFile?.publicURL && (
                    <a
                      href={props.specificationBrochure.localFile.publicURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download Specification Brochure
                    </a>
                  )}
                </div>
              </div>
            ) : null}
          </div>
          <div className="gallery">
            {props.specVideo && (
              <div className="video-wrapper">
                <iframe
                  title={`video-banner-${props.specVideo.replace(
                    "https://www.youtube.com/watch?v=",
                    ""
                  )}`}
                  src={convertYoutubeLinkToEmbed(props.specVideo)}
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            )}
            {!props.specVideo && props.specVideoFallback && (
              <div className="video-fallback">
                <Img
                  fluid={
                    props.specVideoFallback.localFile.childImageSharp.fluid
                  }
                />
              </div>
            )}
            <div className="gallery-images">
              {props.specGallery && (
                <Img
                  fluid={props.specGallery.localFile.childImageSharp.fluid}
                />
              )}
              {props.specGallery2 && (
                <Img
                  fluid={props.specGallery2.localFile.childImageSharp.fluid}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default connector(ProductOverview);
