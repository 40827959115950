import pin from "../../../images/map-marker-alt-solid.png";
import pdfIcon from "../../../images/pdf-download.png";
import downloadIcon from "../../../images/blue-download.png";

export default (theme) => ({
  productOverview: {
    position: "relative",
    marginBottom: 36,
    zIndex: 0,
    [theme.breakpoints.up("md")]: {
      marginBottom: 100,
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: 150,
    },
    "& h2": {
      fontSize: 18,
      letterSpacing: "0",
      lineHeight: 1.5,
      marginTop: 60,
      [theme.breakpoints.up("md")]: {
        marginTop: 30,
        fontSize: 32,
        letterSpacing: "0.008em",
        marginBottom: 0,
      },
    },
    "& .overview-nav": {
      zIndex: 1,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      marginBottom: 60,
      [theme.breakpoints.up("md")]: {
        marginBottom: 50,
      },
      "& .wrapper": {
        maxWidth: 1245,
        margin: "0 auto",
        "& button": {
          position: "relative",
          fontSize: 16,
          letterSpacing: "0.008em",
          lineHeight: 1,
          padding: "12px 20px",
          borderRadius: 20,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          color: theme.palette.secondary.main,
          fontWeight: "bold",
          background: "transparent",
          border: "none",
          transition: "background-color .3s ease-in-out, color .3s ease-in-out",
          fontFamily: theme.headerFont,
          marginRight: 5,
          [theme.breakpoints.up("md")]: {
            padding: "20px 40px",
            fontSize: 18,
          },
          "&:hover, &:focus": {
            backgroundColor: "#ecedef",
          },
          '&[data-active="true"]': {
            backgroundColor: "#ecedef",
          },
        },
      },
    },
    "& .overview": {
      position: "relative",
      "&:before": {
        content: "''",
        display: "block",
        position: "absolute",
        backgroundColor: "#ecedef",
        top: -60,
        bottom: 0,
        width: "100vw",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: -1,
        [theme.breakpoints.up("md")]: {
          bottom: -50,
          top: -50,
        },
      },
      [theme.breakpoints.up("md")]: {
        width: "87.5%",
        marginLeft: "6.25%",
      },
      "& h2": {
        padding: "0 12px",
      },
      "& .overview-columns": {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        padding: "0 12px",
        "& .content": {
          flex: "0 0 100%",
          maxWidth: "100%",
          [theme.breakpoints.up("md")]: {
            flex: "0 0 42.85%",
            maxWidth: "42.85%",
            marginRight: "7.14%",
          },
          "& .product-overview-icons": {
            [theme.breakpoints.up("md")]: {
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "12px 0 24px",
            },
            "& .icon-line": {
              padding: "5px 10px",
              [theme.breakpoints.up("md")]: {
                padding: 0,
              },
              "& .icon": {
                width: 40,
                height: 40,
                border: "1px solid " + theme.palette.primary.main,
                borderRadius: "50%",
                textAlign: "center",
                padding: 5,
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: 24,
                [theme.breakpoints.up("md")]: {
                  width: 94,
                  height: 94,
                  display: "block",
                  marginRight: 0,
                  marginBottom: 20,
                  padding: 15,
                },
              },
              "& .text": {
                fontSize: 16,
                lineHeight: 1.5,
                fontWeight: 500,
                letterSpacing: "0.016em",
                color: theme.palette.primary.main,
                [theme.breakpoints.up("md")]: {
                  fontSize: 10,
                  letterSpacing: 0,
                  lineHeight: 1.5,
                  display: "block",
                  textAlign: "center",
                },
              },
            },
          },
          "& p": {
            fontSize: 16,
            lineHeight: 1.5,
            letterSpacing: "0.032em",
            margin: "32px 0",
            [theme.breakpoints.up("md")]: {
              margin: "16px 0 32px",
            },
            "& a": {
              color: theme.palette.primary.main,
            },
          },
          "& li": {
            fontSize: 16,
            lineHeight: 1.5,
            letterSpacing: "0.03em",
            marginBottom: 12,
          },
          "& .actions": {
            position: "relative",
            "& a, & button": {
              borderRadius: 26,
              fontFamily: theme.bodyFont,
              fontSize: 18,
              letterSpacing: "0.008em",
              lineHeight: 2,
              fontWeight: 700,
              border: "none",
              padding: "10px 24px",
              marginTop: 24,
              marginRight: 24,
              position: "relative",
              display: "inline-table",
              textDecoration: "none",
              "&::after": {
                content: '""',
                display: "inline-block",
                marginLeft: 12,
                width: 22,
                height: 20,
                verticalAlign: "text-top",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              },
              "&.brochure": {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                boxShadow: "0 24px 26px rgba(0,0,0,0.16)",
                transition: "background-color .3s ease-in-out",
                "&::after": {
                  backgroundImage: "url(" + pdfIcon + ")",
                  backgroundSize: "cover",
                  height: 22,
                },
                "&:hover, &:focus": {
                  backgroundColor: theme.palette.primary.dark,
                },
              },
              "&.stockists": {
                backgroundColor: theme.palette.primary.contrastText,
                color: theme.palette.primary.main,
                boxShadow: "0 24px 26px rgba(0,0,0,0.16)",
                "&::after": {
                  backgroundImage: "url(" + pin + ")",
                  height: 22,
                },
              },
            },
          },
          "& .usefull-links": {
            marginTop: 50,
            display: "block",
            borderRadius: 8,
            padding: 30,
            backgroundColor: "#f6f7f7",
            "& h4": {
              marginTop: 0,
              fontSize: 24,
              lineHeight: 1,
              fontFamily: theme.headerFont,
              fontWeight: 500,
              display: "block",
              [theme.breakpoints.up("md")]: {
                display: "inline-block",
                width: 180,
                verticalAlign: "top",
              },
            },
            "& .links": {
              display: "inline-block",
              borderTop: "1px solid #caced2",
              paddingTop: 25,
              [theme.breakpoints.up("md")]: {
                borderTop: "none",
                borderLeft: "1px solid #caced2",
                paddingTop: 0,
                paddingLeft: 25,
              },
              "& a": {
                color: theme.palette.primary.main,
                fontWeight: "bold",
                fontSize: 18,
                letterSpacing: "0.008em",
                textDecoration: "transform",
                display: "table",
                paddingRight: 25,
                backgroundImage: "url(" + downloadIcon + ")",
                backgroundSize: "9px 14px",
                backgroundPosition: "center right",
                backgroundRepeat: "no-repeat",
                "&:first-of-type": {
                  marginBottom: 20,
                },
              },
            },
          },
        },
        "& .gallery": {
          [theme.breakpoints.up("md")]: {
            flex: "0 0 50%",
            maxWidth: "50%",
            padding: "0 12px",
            marginTop: -75,
          },
          [theme.breakpoints.up("lg")]: {
            flex: "0 0 56.25%",
            maxWidth: "56.25%",
          },
        },
        "& .SocialShare": {
          position: "absolute",
          top: 24,
          right: 0,
          paddingBottom: "4px !important",
          paddingTop: "4px !important",
          transition: "all .3s ease-in-out !important",
          [theme.breakpoints.up("md")]: {
            right: "auto",
            left: 200,
          },
          "&:not([open])": {
            boxShadow: "0 0 0 rgba(0,0,0,0)",
            backgroundColor: "transparent",
            "& button": {
              color: "#101c32",
            },
          },
          "& button": {
            margin: "0 !important",
            padding: "12px 24px !important",
            lineHeight: "23px !important",
            color: "#434491",
            transition: "all .3s ease-in-out !important",
            "&::after": {
              display: "none !important",
            },
          },
          "& .buttons": {
            "& a": {
              display: "block !important",
              padding: "12px 0 !important",
              fontSize: "16px !important",
              margin: "0 0 5px !important",
              textAlign: "center !important",
              "&::after": {
                display: "none !important",
              },
            },
          },
        },
      },
      "& .product-accreditations": {
        display: "flex",
        gap: 24,
        paddingBottom: 24,
        marginTop: -12,
        "& .accred-logo": {
          width: 88,
        },
      },
      "& .features": {
        [theme.breakpoints.up("md")]: {
          padding: "0 12px",
          flex: "0 0 50%",
          maxWidth: "50%",
        },
        "& .features-list": {
          paddingBottom: 12,
        },
        "& h3": {
          fontSize: 16,
          letterSpacing: "0.032em",
          lineHeight: 1.5,
          marginTop: 32,
          marginBottom: 32,
          [theme.breakpoints.up("md")]: {
            marginTop: 0,
          },
        },
        "& ul": {
          "& li": {
            fontSize: 16,
            letterSpacing: "0.032em",
            lineHeight: 1.5,
            marginBottom: 16,
          },
        },
      },
    },
    "& .product-faqs": {
      position: "relative",
      width: "calc(100% - 24px)",
      marginLeft: 12,
      [theme.breakpoints.up("md")]: {
        width: "calc(87.5% - 24px)",
        marginLeft: "calc(6.25% + 12px)",
      },
      "&:before": {
        content: "''",
        display: "block",
        position: "absolute",
        backgroundColor: "#ecedef",
        top: -60,
        bottom: 0,
        width: "100vw",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: -1,
        [theme.breakpoints.up("md")]: {
          bottom: -50,
          top: -50,
        },
      },
      "& h2": {
        marginBottom: 32,
      },
      "& .MuiPaper-root": {
        backgroundColor: "transparent",
      },
      "& .accordion": {
        maxWidth: "100%",
        marginLeft: 0,
        marginRight: 0,
      },
    },
    "& .specifiers": {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      position: "relative",
      "&:before": {
        content: "''",
        display: "block",
        position: "absolute",
        backgroundColor: "#ecedef",
        top: -60,
        bottom: 0,
        width: "100vw",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: -1,
        [theme.breakpoints.up("md")]: {
          bottom: -50,
          top: -50,
        },
      },
      "& .content-col": {
        padding: "0 12px",
        flex: "0 0 100%",
        maxWidth: "100%",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: "0.032em",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 50%",
          maxWidth: "50%",
        },
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 37.5%",
          maxWidth: "37.5%",
          marginLeft: "6.25%",
        },
        "& h2": {
          marginTop: 0,
          marginBottom: 16,
        },
        "& p": {
          fontSize: 16,
          lineHeight: 1.5,
          letterSpacing: "0.032em",
          margin: "32px 0",
          [theme.breakpoints.up("md")]: {
            margin: "16px 0 32px",
          },
        },
        "& li": {
          fontSize: 16,
          lineHeight: 1.5,
          letterSpacing: "0.03em",
          marginBottom: 12,
        },
        "& .actions": {
          position: "relative",
          "& a, & button": {
            borderRadius: 26,
            fontFamily: theme.bodyFont,
            fontSize: 18,
            letterSpacing: "0.008em",
            lineHeight: 2,
            fontWeight: 700,
            border: "none",
            padding: "10px 24px",
            marginTop: 24,
            marginRight: 24,
            position: "relative",
            display: "inline-table",
            textDecoration: "none",
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.main,
            boxShadow: "0 24px 26px rgba(0,0,0,0.16)",
          },
        },
        "& .usefull-links": {
          marginTop: 50,
          display: "block",
          borderRadius: 8,
          padding: 30,
          backgroundColor: "#f6f7f7",
          "& h4": {
            marginTop: 0,
            fontSize: 24,
            lineHeight: 1,
            fontFamily: theme.headerFont,
            fontWeight: 500,
            display: "block",
            [theme.breakpoints.up("md")]: {
              display: "inline-block",
              width: 180,
              verticalAlign: "top",
            },
          },
          "& .links": {
            display: "inline-block",
            borderTop: "1px solid #caced2",
            paddingTop: 25,
            [theme.breakpoints.up("md")]: {
              borderTop: "none",
              borderLeft: "1px solid #caced2",
              paddingTop: 0,
              paddingLeft: 25,
            },
            "& a": {
              color: theme.palette.primary.main,
              fontWeight: "bold",
              fontSize: 18,
              letterSpacing: "0.008em",
              textDecoration: "transform",
              display: "table",
              paddingRight: 25,
              backgroundImage: "url(" + downloadIcon + ")",
              backgroundSize: "9px 14px",
              backgroundPosition: "center right",
              backgroundRepeat: "no-repeat",
              marginBottom: 20,
              "&:last-of-type": {
                marginBottom: 0,
              },
            },
          },
        },
      },
      "& .gallery": {
        padding: "0 12px",
        flex: "0 0 100%",
        maxWidth: "100%",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 50%",
          maxWidth: "50%",
        },
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 56.25%",
          maxWidth: "56.25%",
        },
        "& .video-wrapper": {
          paddingBottom: "56% !important",
          width: "100%",
          position: "relative",
          backgroundColor: "#caced2",
          marginBottom: 24,
          overflow: "hidden",
          "& iframe": {
            position: "absolute",
            top: -1,
            left: -1,
            width: "calc(100% + 2px) !important",
            height: "calc(100% + 2px) !important",
          },
        },
        "& .video-fallback": {
          marginBottom: 24,
          width: "100%",
        },
        "& .gallery-images": {
          "& > div:nth-of-type(1)": {
            display: "inline-block",
            width: "calc(55.555% - 12px)",
            marginRight: 12,
            height: 215,
          },
          "& > div:nth-of-type(2)": {
            display: "inline-block",
            width: "calc(44.4444% - 12px)",
            marginLeft: 12,
            height: 215,
          },
        },
      },
    },
  },
  productOverviewTheme: {
    ...theme.productOverviewTheme,
  },
});
