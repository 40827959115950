import React, { Component } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";

import ProductOverview from "../components/Product/ProductOverview";
import ProductAlsoNeed from "../components/Product/ProductAlsoNeed";
import ProductFix from "../components/Product/ProductFix";
import ProductRelatedProducts from "../components/Product/ProductRelatedProducts";
import ProductOverlay from "../components/Product/ProductOverlay";
import Breadcrumb from "../components/Breadcrumb";
import ProductBuilder from "../components/Product/ProductBuilder";
import ProductBuilderNoVariation from "../components/Product/ProductBuilderNoVariation";
import ProductCaseStudy from "../components/Product/ProductCaseStudy";

class Product extends Component {
  componentDidMount() {
    this.props.location.state?.scroll &&
      setTimeout(() => scrollTo(".product-faqs"), 100);
  }
  render() {
    let catStack = [];

    if (this.props.data.wpProduct.productCategories !== null) {
      if (this.props.data.wpProduct.productCategories.nodes.length === 1) {
        catStack.push(this.props.data.wpProduct.productCategories.nodes[0]);
      } else {
        this.props.data.wpProduct.productCategories.nodes.forEach(
          (category) => {
            if (category.ancestors !== null) {
              if (category.ancestors.nodes.length >= catStack.length) {
                catStack = [
                  { id: category.id, name: category.name, slug: category.slug },
                ];
                category.ancestors.nodes.forEach((ancestor) => {
                  catStack.push(ancestor);
                });
              }
            }
          }
        );
      }
    }

    if (catStack.length > 1) {
      catStack.reverse();
    }

    const mayAlsoNeed = this.props.data.wpProduct.product.mayAlsoNeed
      ?.filter((product) => product)
      .filter((product) => {
        if (!product.product) return false;
        if (!product.product.status === "publish") return false;
        return product;
      });

    const relatedProducts = this.props.data.wpProduct.product.relatedProducts
      ?.filter((product) => product)
      .filter((product) => product.product)
      .filter((product) => {
        if (!product.product) return false;
        if (!product.product.status === "publish") return false;
        return product;
      });

    return (
      <Layout
        meta={{
          ...this.props.data.wpProduct.Meta,
          canonical: this.props.data.wpProduct.uri,
        }}
        path={this.props.pageContext.pagePath}
        cta={this.props.data.wpProduct.PageCTA}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <Breadcrumb
          type="product"
          current={{
            title: this.props.data.wpProduct.title,
            slug: this.props.data.wpProduct.slug,
            uri: this.props.data.wpProduct.uri,
          }}
          ancestors={catStack}
        />
        {this.props.data.wpProduct?.product?.variations ? (
          <ProductBuilder product={this.props.data.wpProduct} />
        ) : (
          <ProductBuilderNoVariation product={this.props.data.wpProduct} />
        )}
        <ProductOverview
          title={this.props.data.wpProduct.title}
          overview={this.props.data.wpProduct.product.overview}
          path={this.props.pageContext.pagePath}
          faqs={this.props.data.wpProduct.product.faqs}
          datasheet={this.props.data.wpProduct.product.datasheetFile}
          brochure={this.props.data.wp.themeOptions.ThemeOptions.brochure}
          specificationBrochure={
            this.props.data.wp.themeOptions.ThemeOptions.specificationBrochure
          }
          bmi={this.props.data.wpProduct.product.bmiFile}
          instruction={this.props.data.wpProduct.product.instructionFile}
          features={this.props.data.wpProduct.product.features}
          specBlurb={this.props.data.wpProduct.product.specBlurb}
          specVideo={this.props.data.wpProduct.product.specVideo}
          specVideoFallback={
            this.props.data.wpProduct.product.specVideoFallback
          }
          specGallery={this.props.data.wpProduct.product.specGallery}
          specGallery2={this.props.data.wpProduct.product.specGallery2}
          pageContext={this.props.pageContext}
          accreditations={this.props.data.wpProduct.accreditations}
        />
        {mayAlsoNeed && <ProductAlsoNeed products={mayAlsoNeed} />}
        {this.props.data.wpProduct.product.hasHowtoGuide === true && (
          <ProductFix
            install={{
              description: this.props.data.wpProduct.product.installDescription,
              video: this.props.data.wpProduct.product.installVideo,
              guide: this.props.data.wpProduct.product.installGuide,
            }}
            fix={{
              description: this.props.data.wpProduct.product.fixDescription,
              video: this.props.data.wpProduct.product.fixVideo,
              guide: this.props.data.wpProduct.product.fixGuide,
            }}
            slug={this.props.data.wpProduct.slug}
          />
        )}
        {relatedProducts && (
          <ProductRelatedProducts products={relatedProducts} />
        )}
        <ProductOverlay
          code={this.props.data.wpProduct.product.code}
          uri={this.props.data.wpProduct.uri}
        />
        {this.props?.data?.wpProduct?.product?.caseStudies ? (
          <ProductCaseStudy
            caseStudies={this.props.data.wpProduct.product.caseStudies}
          />
        ) : null}
      </Layout>
    );
  }
}

export const query = graphql`
  query($id: String) {
    wpProduct(id: { eq: $id }, status: { eq: "publish" }) {
      id
      slug
      title
      uri
      featuredImage {
        node {
          sourceUrl
        }
      }
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      product {
        productGallery {
          id
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        shortDescription
        code
        overview
        variations {
          variantName
          variantCode
          option1Name
          option1Value
          option1Image {
            id
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 80, maxWidth: 1660) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          option1Icon {
            localFile {
              publicURL
            }
          }
          option2Name
          option2Value
          option2Image {
            id
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 80, maxWidth: 1660) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          option2Icon {
            localFile {
              publicURL
            }
          }
          option3Name
          option3Value
          option3Image {
            id
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 80, maxWidth: 1660) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          option3Icon {
            localFile {
              publicURL
            }
          }
          option4Name
          option4Value
          option4Image {
            id
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 80, maxWidth: 1660) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          option4Icon {
            localFile {
              publicURL
            }
          }
        }
        hasHowtoGuide
        installDescription
        installVideo
        installGuide
        fixDescription
        fixVideo
        fixGuide

        datasheetFile {
          localFile {
            publicURL
          }
        }

        instructionFile {
          localFile {
            publicURL
          }
        }

        bmiFile {
          localFile {
            publicURL
          }
        }

        features {
          feature
        }

        faqs {
          question
          answer
        }

        specBlurb
        specVideo
        specVideoFallback {
          id
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        specGallery {
          id
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        specGallery2 {
          id
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }

        relatedProducts {
          product {
            ... on WpProduct {
              id
              title
              uri
              link
              status
              featuredImage {
                node {
                  sourceUrl
                }
              }
              product {
                code
                description
                fieldGroupName
                fixDescription
                fixGuide
                fixVideo
                hasHowtoGuide
                installDescription
                installGuide
                installVideo
                introduction
                overview
                shortDescription
              }
              brands {
                nodes {
                  BrandExtras {
                    logo {
                      localFile {
                        childImageSharp {
                          fluid(quality: 80, maxWidth: 1660) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        images360 {
          id
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }

        caseStudies {
          ... on WpCaseStudy {
            id
            title
            uri
            date
            excerpt
            featuredImage {
              node {
                id
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
      productCategories {
        nodes {
          id
          name
          slug
          uri
          ancestors {
            nodes {
              id
              name
              slug
              uri
            }
          }
        }
      }
      accreditations {
        nodes {
          AccreditationLogo {
            logo {
              id
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      brands {
        nodes {
          BrandExtras {
            logo {
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            backgroundColor
            backgroundTexture {
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
                publicURL
              }
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
          brochure {
            localFile {
              publicURL
            }
            title
            sourceUrl
            id
          }
          specificationBrochure {
            localFile {
              publicURL
            }
            title
            sourceUrl
            id
          }
        }
      }
    }
  }
`;

export default Product;
