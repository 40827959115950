import React, { useState, useEffect, useRef } from "react";
import Img from "gatsby-image";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import Slider from "react-slick";
import ProductViewer from "../ProductBuilder/ProductViewer";
import styles from "./styles";

import {
  addToWishlist,
  removeFromWishlist,
  openOverlay,
} from "../../../app/actions";

const useStyles = makeStyles((theme) => styles(theme));

const mapStateToProps = (state) => ({
  wishlist: state.WishlistSettings,
});

const mapDispatchToProps = (dispatch) => ({
  addToWishlist: (item) => dispatch(addToWishlist(item)),
  removeFromWishlist: (item) => dispatch(removeFromWishlist(item)),
  openOverlay: () => dispatch(openOverlay()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

function ProductBuilderNoVariations(props) {
  const classes = useStyles();
  const [quantity, setQuantity] = useState(1);

  // state for the slider
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const sliderRef = useRef(null);
  const navSliderRef = useRef(null);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    focusOnSelect: false,
    arrows: false,
    adaptiveHeight: false,
    asNavFor: nav2,
    touchMove: false,
    fade: true,
  };

  const navSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    adaptiveHeight: false,
    focusOnSelect: true,
    asNavFor: nav1,
    variableWidth: true,
  };

  // remount slider on init
  useEffect(() => {
    setNav1(sliderRef.current);
    setNav2(navSliderRef.current);
  }, []);

  const productImages = props?.product?.product?.productGallery;
  const { images360 } = props?.product?.product;

  const selectedProductCode = props?.product?.product?.code;

  function wishlistHandler() {
    const { wishlist, addToWishlist, removeFromWishlist } = props;

    if (wishlist.includes(selectedProductCode)) {
      removeFromWishlist(selectedProductCode);
    } else {
      addToWishlist(selectedProductCode);
    }
  }

  // combine slides and optionImages but only include first image from each option
  const slides = productImages ? productImages.filter((slide) => slide) : null;

  return (
    <div className={`${classes.productBuilder}`}>
      <div className="product-data">
        <div className="texture" />
        <h1>{props?.product?.title}</h1>
        <p>{props?.product?.product?.shortDescription}</p>

        <div className="product-selector">
          <div className="product-code">
            <h4>Product Code</h4>
            {selectedProductCode ? (
              <span className="code">{selectedProductCode || ""}</span>
            ) : (
              <span className="wait">Awaiting Confirmation</span>
            )}
          </div>
        </div>

        {selectedProductCode ? (
          <div className="product-quantity">
            <h3>Quantity</h3>
            <input
              type="number"
              className=""
              value={quantity}
              onChange={(e) => setQuantity(e.target.valueAsNumber)}
            />
            <button onClick={() => wishlistHandler()}>Add to Quote</button>
          </div>
        ) : null}
      </div>
      {slides ? (
        <div className="product-gallery">
          <div
            className="product-gallery-inner"
            style={{
              "--slick-slider-maxheight": `${
                slides.length > 4
                  ? `calc(60px + 112px * ${slides.length})`
                  : "560px"
              }`,
            }}
          >
            <Slider ref={sliderRef} className="product-slider" {...settings}>
              {images360 && (
                <div>
                  <div className="">
                    <ProductViewer images={images360} />
                  </div>
                </div>
              )}
              {slides.map(({ id, localFile }) => (
                <div key={id}>
                  <div className="">
                    <Img fluid={localFile.childImageSharp.fluid} />
                  </div>
                </div>
              ))}
            </Slider>
            <Slider ref={navSliderRef} {...navSettings} className="slick-nav">
              {images360 && (
                <div>
                  <span className="i360">360</span>
                  <img src={images360[0]?.localFile?.publicURL} alt="360" />
                </div>
              )}

              {slides.map(({ id, localFile }) => (
                <Img
                  key={id}
                  fluid={localFile.childImageSharp.fluid}
                  style={{
                    width: 134,
                    height: 134,
                    aspectRatio: 1,
                  }}
                />
              ))}
            </Slider>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default connector(ProductBuilderNoVariations);
