import React, { useState, useEffect, useRef } from "react";
import Img from "gatsby-image";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import Slider from "react-slick";
import ProductViewer from "./ProductViewer";
import styles from "./styles";

import {
  addToWishlist,
  removeFromWishlist,
  openOverlay,
} from "../../../app/actions";

const useStyles = makeStyles((theme) => styles(theme));

const mapStateToProps = (state) => ({
  wishlist: state.WishlistSettings,
});

const mapDispatchToProps = (dispatch) => ({
  addToWishlist: (item) => dispatch(addToWishlist(item)),
  removeFromWishlist: (item) => dispatch(removeFromWishlist(item)),
  openOverlay: () => dispatch(openOverlay()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

function ProductBuilder(props) {
  const classes = useStyles();
  const [selectedProduct, setSelectedProduct] = useState({});
  const [quantity, setQuantity] = useState(1);

  // state for options
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [selectedOption4, setSelectedOption4] = useState(null);

  // state for the slider
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const sliderRef = useRef(null);
  const navSliderRef = useRef(null);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    focusOnSelect: false,
    arrows: false,
    adaptiveHeight: false,
    asNavFor: nav2,
    touchMove: false,
    fade: true,
  };

  const navSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    adaptiveHeight: false,
    focusOnSelect: true,
    asNavFor: nav1,
    variableWidth: true,
  };

  // remount slider on init
  useEffect(() => {
    setNav1(sliderRef.current);
    setNav2(navSliderRef.current);
  }, []);

  const productImages = props?.product?.product?.productGallery;
  const { images360 } = props?.product?.product;
  const variations = props?.product?.product?.variations;

  const option1 = variations
    ? variations
        .map((variation) => {
          return {
            name: variation?.option1Name,
            value: variation?.option1Value,
            image: variation?.option1Image,
            icon: variation?.option1Icon?.localFile?.publicURL,
            code: variation?.variantCode,
          };
        })
        // filter out duplicates by value
        .filter(
          (variation, index, self) =>
            index === self.findIndex((t) => t.value === variation.value)
        )
    : [];

  const [isDefaultOptionSet, setIsDefaultOptionSet] = useState(false);

  // setSelectedOption1
  useEffect(() => {
    if (!isDefaultOptionSet) {
      setSelectedOption1(option1[0]?.value);
      setIsDefaultOptionSet(true);
    }
  }, [option1]);

  const option2 = variations
    ? variations
        .map((variation) => {
          return {
            name: variation?.option2Name,
            value: variation?.option2Value,
            option1Value: variation?.option1Value
              .toLowerCase()
              .replace(" ", "-"),
            image: variation?.option2Image,
            icon: variation?.option2Icon?.localFile?.publicURL,
            code: variation?.variantCode,
          };
        })
        // filter out duplicates by selectedOption1
        .filter(
          (variation) =>
            variation.option1Value ===
            selectedOption1?.toLowerCase().replace(" ", "-")
        )
    : [];

  const options = [];

  if (option1.length > 0) {
    options.push({
      number: 1,
      name: option1[0].name,
      options: option1,
    });
  }

  if (option2.length > 0) {
    if (option2[0].name) {
      options.push({
        number: 2,
        name: option2[0].name,
        options: option2,
      });
    }
  }

  const selectedProductOption = variations?.find(
    (variation) =>
      // if only option1 is selected match on option1Value only, otherwise match on all options
      (selectedOption1 && !selectedOption2
        ? variation?.option1Value === selectedOption1
        : variation?.option1Value === selectedOption1 &&
          variation?.option2Value === selectedOption2) ||
      (variation?.option1Value === selectedOption1 &&
        variation?.option2Value === selectedOption2 &&
        variation?.option3Value === selectedOption3 &&
        variation?.option4Value === selectedOption4)
  );

  // get last optionXImage from selectedProductOption
  const selectedProductImageId =
    selectedOption1 && !selectedOption2
      ? selectedProductOption?.option1Image?.id ?? null
      : selectedProductOption?.option4Image?.id ??
        selectedProductOption?.option3Image?.id ??
        selectedProductOption?.option2Image?.id ??
        selectedProductOption?.option1Image?.id ??
        null;

  const selectedOptionMap = {
    1: selectedOption1,
    2: selectedOption2,
    3: selectedOption3,
    4: selectedOption4,
  };

  const setSelectedOptionMap = {
    1: setSelectedOption1,
    2: setSelectedOption2,
    3: setSelectedOption3,
    4: setSelectedOption4,
  };

  const selectedProductCode = selectedProductOption?.variantCode;

  const selectedOptions = [
    selectedOption1,
    selectedOption2,
    selectedOption3,
    selectedOption4,
  ].filter((option) => option);

  function wishlistHandler() {
    const { wishlist, addToWishlist, removeFromWishlist } = props;

    if (wishlist.includes(selectedProductCode)) {
      removeFromWishlist(selectedProductCode);
    } else {
      addToWishlist(selectedProductCode);
    }
  }

  const optionImages = [];

  if (variations) {
    variations.forEach((variation) => {
      if (variation?.option1Image) optionImages.push(variation?.option1Image);
      if (variation?.option2Image) optionImages.push(variation?.option2Image);
      if (variation?.option3Image) optionImages.push(variation?.option3Image);
      if (variation?.option4Image) optionImages.push(variation?.option4Image);
    });
  }

  // combine slides and optionImages but only include first image from each option
  const slides = productImages
    ? productImages
        .concat(
          optionImages
            ? // flatten the array
              optionImages.reduce((acc, val) => acc.concat(val), [])
            : optionImages
        )
        .filter((slide) => slide)
    : null;

  // get the index of the slide that matches the selectedProduct imageId
  const slideIndex = slides
    ? images360
      ? slides.findIndex((slide) => selectedProductImageId === slide?.id) + 1
      : slides.findIndex((slide) => selectedProductImageId === slide?.id)
    : null;

  // slide to slideIndex if matching image found
  useEffect(() => {
    if (slideIndex !== -1 && slides.length > 1) {
      sliderRef.current.slickGoTo(slideIndex);
    }
  }, [slideIndex]);

  return (
    <div className={`${classes.productBuilder}`}>
      <div className="product-data">
        <div className="texture" />
        <h1>{props?.product?.title}</h1>
        <p>{props?.product?.product?.shortDescription}</p>
        {options ? (
          <div className="product-selector">
            <span>Select:</span>
            {options.map((option, index) => {
              if (!option.name || !option.options) return null;

              // disable option if previous option not in selectedProduct
              const disabled =
                index > 0 &&
                !selectedProduct?.[options[index - 1].name?.toLowerCase()];
              return (
                <div
                  key={option.name}
                  className={`product-option${
                    option.name?.toLowerCase().includes("colour")
                      ? " color"
                      : ""
                  }`}
                >
                  <h3>{option.name}</h3>
                  <div className="selection">
                    {option.options.map((subOption) => {
                      return (
                        <button
                          key={subOption.value}
                          className={`${selectedOptionMap[option.number] ===
                            subOption.value && "selected"}`}
                          onClick={() =>
                            setSelectedOptionMap[option.number](subOption.value)
                          }
                          data
                        >
                          {subOption.icon ? (
                            <img
                              src={subOption.icon}
                              alt={`${subOption.name} image`}
                            />
                          ) : (
                            <img
                              src="https://via.placeholder.com/62"
                              alt="placeholder"
                            />
                          )}
                          <span>{subOption.value}</span>
                        </button>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <div className="product-code">
              <h4>Product Code</h4>
              {selectedProductCode &&
              selectedOptions.length === options.length ? (
                <span className="code">{selectedProductCode}</span>
              ) : (
                <span className="wait">Awaiting Confirmation</span>
              )}
            </div>
          </div>
        ) : null}
        {selectedProductCode && selectedOptions.length === options.length ? (
          <div className="product-quantity">
            <h3>Quantity</h3>
            <input
              type="number"
              className=""
              value={quantity}
              onChange={(e) => setQuantity(e.target.valueAsNumber)}
            />
            <button onClick={() => wishlistHandler()}>Add to Quote</button>
          </div>
        ) : null}
      </div>
      {slides ? (
        <div className="product-gallery">
          <div
            className="product-gallery-inner"
            style={{
              "--slick-slider-maxheight": `${
                slides.length > 4
                  ? `calc(60px + 112px * ${slides.length})`
                  : "560px"
              }`,
            }}
          >
            <Slider ref={sliderRef} className="product-slider" {...settings}>
              {images360 && (
                <div>
                  <div className="">
                    <ProductViewer images={images360} />
                  </div>
                </div>
              )}
              {slides.map(({ id, localFile }) => (
                <div key={id}>
                  <div className="">
                    <Img fluid={localFile.childImageSharp.fluid} />
                  </div>
                </div>
              ))}
            </Slider>
            <Slider ref={navSliderRef} {...navSettings} className="slick-nav">
              {images360 && (
                <div>
                  <span className="i360">360</span>
                  <img src={images360[0]?.localFile?.publicURL} alt="360" />
                </div>
              )}

              {slides.map(({ id, localFile }) => (
                <Img
                  key={id}
                  fluid={localFile.childImageSharp.fluid}
                  style={{
                    width: 134,
                    height: 134,
                    aspectRatio: 1,
                  }}
                />
              ))}
            </Slider>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default connector(ProductBuilder);
