import mobileProdWave from "../../../images/mobile-prod-wave.png";
import desktopProdWave from "../../../images/desktop-prod-wave.png";
import quoteIcon from "../../../images/quote-icon.png";
import sliderArrow from "../../../images/button-arrow.png";
import i360 from "../../../images/360-cam.png";

export default (theme) => ({
  productBuilder: {
    position: "relative",
    zIndex: 1,
    backgroundColor: "white",
    marginBottom: 50,
    boxShadow: "-36px 0 0 white, 36px 0 0 white",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      // marginBottom: 120,
    },
    "& .product-data": {
      position: "relative",
      width: "calc(100% + 36px)",
      margin: "0 -18px",
      padding: "44px 18px 50px",
      [theme.breakpoints.up("md")]: {
        flex: "0 0 50%",
        width: "41.333%",
        maxWidth: "41.333%",
        padding: "50px 12px 50px 0",
        margin: 0,
      },
      "& .texture": {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: -1,
        backgroundColor: theme.palette.tertiary.main,
        [theme.breakpoints.up("md")]: {
          width: "50vw",
          left: "auto",
        },
        "&::before": {
          position: "absolute",
          content: '""',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom left",
          backgroundSize: "contain",
          backgroundImage: `url(${mobileProdWave})`,
          boxShadow: "500px -500px 0px 500px white",

          [theme.breakpoints.up("md")]: {
            backgroundPosition: "bottom right",
            backgroundSize: "cover",
            backgroundImage: `url(${desktopProdWave})`,
          },
        },
      },
      "& h1": {
        fontSize: 32,
        fontWeight: "bold",
        letterSpacing: "0.008em",
        lineHeight: "48px",
        marginBottom: 16,
        paddingRight: "20%",
      },
      "& p": {
        fontSize: 14,
        letterSpacing: "0.032em",
        lineHeight: "24px",
        marginBottom: 16,
        paddingRight: "20%",
      },
      "& .product-selector": {
        backgroundColor: theme.common.white,
        borderRadius: 11,
        paddingTop: 20,
        maxWidth: 410,
        overflow: "hidden",
        "& span": {
          fontSize: 20,
          lineHeight: 1,
          padding: "0 32px",
          marginBottom: 18,
          color: theme.palette.primary.main,
          opacity: 0.36,
          display: "block",
        },
        "& .product-option": {
          padding: "0 32px",
          display: "block",
          "&.disabled": {
            opacity: 0.5,
            pointerEvents: "none",
            cursor: "not-allowed",
          },
          "& h3": {
            fontSize: 16,
            letterSpacing: "0",
            marginBottom: 10,
            color: theme.palette.primary.main,
            fontWeight: "bold",
            marginTop: 0,
          },
          "& .selection": {
            marginBottom: 8,
            "& button": {
              border: "1px solid rgb(168,173,180)",
              borderRadius: 10,
              padding: 0,
              backgroundColor: "transparent",
              marginRight: 8,
              marginBottom: 8,
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: 165,
              outline: "1px solid transparent",
              transition: "border .3s ease-in-out, outline .3s ease-in-out",
              fontFamily: theme.bodyFont,
              "&:not(.false), &.selected": {
                border: "1px solid rgba(66,68,150)",
                outline: "1px solid rgba(66,68,150)",
              },
              "& img": {
                borderRadius: 10,
                marginRight: 8,
                width: 64,
                height: 64,
              },
              "& span": {
                textAlign: "center",
                fontSize: 16,
                letterSpacing: "0.016em",
                lineHeight: "20px",
                fontWeight: "normal",
                color: theme.palette.primary.main,
                marginBottom: 0,
                padding: 0,
                opacity: 1,
                display: "block",
                flex: 1,
              },
            },
          },

          "&.color": {
            "& .selection": {
              "& button": {
                borderRadius: "50%",
                width: "auto",
                overflow: "hidden",
                "& img": {
                  width: 32,
                  height: 32,
                  marginRight: 0,
                  borderRadius: "50%",
                },
                "& span": {
                  display: "none",
                },
              },
            },
          },
        },

        "& .product-code": {
          backgroundColor: "rgba(16,28,50,.23)",
          padding: "16px 32px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 32,
          "& h4": {
            fontSize: 10,
            fontWeight: "normal",
            letterSpacing: "0.152em",
            marginRight: 4,
            marginTop: 0,
            marginBottom: 0,
            textTransform: "uppercase",
          },

          "& span": {
            opacity: 1,
            fontSize: 16,
            letterSpacing: "0.008em",
            margin: 0,
            padding: 0,
            "&.code": {},
            "&.wait": { opacity: 0.24 },
          },
        },
      },

      "& .product-quantity": {
        backgroundColor: theme.common.white,
        borderRadius: 11,
        boxShadow: "0 24px 26px rgba(0,0,0,0.16)",
        padding: "20px 32px",
        marginTop: 8,
        maxWidth: 410,

        "& h3": {
          fontSize: 10,
          letterSpacing: "0.152em",
          fontWeight: "bold",
          marginTop: 0,
          marginBottom: 10,
          lineHeight: "16px",
          textTransform: "uppercase",
        },

        "& input": {
          width: "100%",
          fontWeight: "bold",
          textAlign: "center",
          display: "block",
          border: "1px solid #A8ADB4",
          marginBottom: 10,
          fontSize: 16,
          letterSpacing: "0.008em",
          color: theme.palette.primary.main,
          padding: 14,
          borderRadius: 26,
          outline: "none",
          fontFamily: theme.bodyFont,
        },

        "& button": {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          padding: "16px 24px",
          borderRadius: 26,
          fontWeight: "900",
          letterSpacing: "0.008em",
          border: "none",
          width: "100%",
          fontSize: 18,
          lineHeight: "20px",
          textAlign: "left",
          backgroundImage: `url(${quoteIcon})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center right 24px",
          fontFamily: theme.bodyFont,
          boxShadow: "0 24px 26px rgba(0,0,0,0.16)",
          transition: "box-shadow .3s ease-in-out",
          marginBottom: 12,

          "&:hover, &:focus": {
            boxShadow: "0 0 0 rgba(0,0,0,0.16)",
          },
        },
      },
    },

    "& .product-gallery": {
      [theme.breakpoints.up("sm")]: {
        margin: "-11% auto 70px",
        maxWidth: 540,
      },

      [theme.breakpoints.up("md")]: {
        flex: "0 0 calc(58.333% - 24px)",
        width: "calc(58.333% - 24px)",
        maxWidth: "calc(58.333% - 24px)",
        marginLeft: 24,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0,
        padding: "60px 0",
      },

      "& .product-slider": {
        flex: "0 0 calc(100% - 108px)",

        [theme.breakpoints.up("md")]: {
          flex: "0 0 calc(100% - 158px)",
          maxHeight: "var(--slick-slider-maxheight)",
        },

        "& .slick-slide": {
          aspectRatio: 1,
        },
      },

      "& .slick-nav": {
        flex: "0 0 84px",
        marginLeft: 24,
        padding: "30px 0",
        maxHeight: 292,
        maxWidth: 84,
        [theme.breakpoints.up("md")]: {
          flex: "0 0 134px",
          maxWidth: 158,
          maxHeight: "var(--slick-slider-maxheight)",
        },

        "& .slick-slide": {
          // width: "58px !important",
          // height: "58px !important",
          margin: "0 12px",
          border: "2px solid transparent",
          padding: 8,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 11,
          fontSize: 0,
          cursor: "pointer",
          maxWidth: "fit-content",

          [theme.breakpoints.up("md")]: {
            // width: "108px !important",
            // height: "108px !important",
          },

          "& > div": {
            position: "relative",
            width: 42,
            aspectRatio: 1,
            display: "inline-grid",
            placeItems: "center",

            [theme.breakpoints.up("md")]: {
              width: 92,
            },

            "& .i360": {
              width: 42,
              height: 42,
              backgroundImage: `url(${i360})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-21px, -21px)",
            },
          },
          "&.slick-current": {
            border: "2px solid #424496",
          },
        },
        "& .slick-arrow": {
          width: "100%",
          left: 0,
          right: 0,
          height: 44,
          zIndex: 10,
          transform: "none",
          transformOrigin: "50% 50%",
          backgroundImage:
            "linear-gradient(0deg, rgba(255,255,255,1) 66.666%, rgba(255,255,255,0) 100%)",
          "&::before": {
            backgroundImage: `url(${sliderArrow})`,
            content: "''",
            width: 44,
            height: 44,
            display: "block",
            backgroundPosition: "right center",
            backgroundRepeat: "no-repeat",
            transform: "rotate(90deg)",
            margin: "0 auto !important",
          },
          "&.slick-prev": {
            top: 0,
            bottom: "auto",
            transform: "rotate(180deg)",
          },
          "&.slick-next": {
            bottom: 0,
            top: "auto",
          },
        },
      },
    },
    "& .product-gallery-inner": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // padding: "32px 0",
    },

    "& .actions-360": {
      position: "absolute",
      "z-index": 2,
      color: "#424496",
    },

    "& abbr[title='Fullscreen Toggle'], .v360-fullscreen-toggle": {
      display: "none!important",
    },
  },
});
